
import { onMounted, defineComponent, reactive, ref, watch } from "vue";
import { provCityCountyTownList } from "@/utils/area-res";
import {
  SysCityModel,
  SysCountyModel,
  SysProvModel,
  SysTownModel
} from "@/apis/interface/ISystem";
export default defineComponent({
  name: "List",
  setup() {
    let provListData = ref<SysProvModel[]>([]);
    let cityListData = ref<SysCityModel[]>([]);
    let countyListData = ref<SysCountyModel[]>([]);
    let townListData = ref<SysTownModel[]>([]);

    let cityTempList: SysCityModel[] = [];
    let countyTempList: SysCountyModel[] = [];
    let townTempList: SysTownModel[] = [];

    let current = reactive({
      currentProvCode: null,
      currentCityCode: null,
      currentCountyCode: null
    });

    let loadDataList = async () => {
      const json = provCityCountyTownList();

      let { provList, cityList, countyList, townList } = json;
      provListData.value = provList.map((item: any) => {
        item.selected = false;
        return item;
      });
      cityTempList = cityList.map((item: any) => {
        item.selected = false;
        return item;
      });
      countyTempList = countyList.map((item: any) => {
        item.selected = false;
        return item;
      });
      townTempList = townList.map((item: any) => {
        item.selected = false;
        return item;
      });
    };

    onMounted(() => {
      loadDataList();
    });

    let selectClick = () => {
      let p = provListData.value.find((item: SysProvModel) => item.selected);
      current.currentProvCode = p?.code as any;

      let c = cityListData.value.find((item: SysCityModel) => item.selected);
      current.currentCityCode = c?.code as any;

      let y = countyListData.value.find(
        (item: SysCountyModel) => item.selected
      );
      current.currentCountyCode = y?.code as any;
    };

    watch(
      () => current.currentProvCode,
      (n) => {
        cityListData.value = cityTempList.filter(
          (item: SysCityModel) => item.provCode == n
        );
        countyListData.value = [];
        townListData.value = [];
      }
    );

    watch(
      () => current.currentCityCode,
      (n) => {
        countyListData.value = countyTempList.filter(
          (item: SysCountyModel) => item.cityCode == n
        );
        townListData.value = [];
      }
    );

    watch(
      () => current.currentCountyCode,
      (n) => {
        townListData.value = townTempList.filter(
          (item: SysTownModel) => item.countyCode == n
        );
      }
    );

    return {
      provListData,
      cityListData,
      countyListData,
      townListData,
      current,
      selectClick
    };
  }
});
